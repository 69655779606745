const enUsTranslations = {
    translations: {
        login: "Login",
        email: "Email",
        password: "Password",
        forgotThePassword: "Forgot the password",
        signin: "Sign-in",
        recoverYourPassword: "Recover your password",
        enterTheEmailRegisterd: "Enter the email registered in the system",
        recoverPassword: "Recover Password",
        collaborators: "Collaborators",
        addCollaborator: "Add Collaborator",
        search: "Search",
        all: "All",
        operator: "Operator",
        administrator: "Administrator",
        role: "Role",
        active: "Active",
        actives: "Actives",
        inactive: "Inactive",
        perfil: "Perfil",
        dashboard: "Dashboard",
        report: "Report",
        logout: "Logout",
        addNewOperator: "Add new operator",
        name: "Name",
        phone: "Phone",
        status: "Status",
        no: "No",
        register: "Register",
        redefinePassword: "Redefine Password",
        oldPassword: "Old Password",
        newPassword: "New Password",
        repeatPassword: "Repeat Password",
        save: "Save",
        invalidEmail: "Invalid email address",
        invalidPassword: "Invalid password",
        collaboratorInfo: "Collaborator Info",
        archive: "Archive",
        edit: "Edit",
        resendEmail: "Resend email",
        desarchive: "Desarchive",
        editCollaboratorInfo: "Edit collaborator info",
        editPerfilInfo: "Edit perfil info",
        cancel: "Cancel",
        archived: "Archived",
        archiveds: "Archiveds",
        awaitingConfirmation: "Awaiting confirmation",
        emailIsRequired: "Email is required",
        passwordIsRequired: "Password is required",
        userOrPasswordNotFound: "User and/or password were not found",
        newPasswordSameAsOld: "The password must be different from the old",
        passwordsDoNotMatch: "Passwords do not match",
        atLeast8Characters: "At least 8 characters",
        atLeastOneUppercaseLetter: "At least one uppercase letter",
        atLeastOneNumber: "At least one number",
        atLeastOneSpecialCharacter: "At least one special character (!@#$%^&*)",
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",
        photocatalyticPanel: "Photocatalytic Panel",
        viewPeriod: "View Period",
        until: "until",
        reactor: "Reactor",
        glycerolTank: "Glycerol Tank",
        lastCommands: "Last Commands",
        productionVolume: "H₂ production volume",
        seeAll: "See all",
        gasFlow: "Gas Flow",
        temperature: "Temperature",
        synchronizationTime: "Synchronization Time",
        waiting: "Waiting",
        completed: "Completed",
        addUserSuccess: "User added successfully!",
        updateUserSuccess: "User updated successfully!",
        insertSecurityCode: "Insert the security code",
        send: "Send",
        invalidCode: "Invalid Code",
        codeLengthError: "Error on the code length",
        changePassword: "Change Password",
        insertSecurityCodeDescription: "Insert the 6-digit code sent to your email:",
        archiveSuccess: "Archived/Desarchived successfully!",
        changeEmail: "Change Email",
        level: "Level",
        pump: "Pump",
        targetpH: "Target pH",
        actualpH: "Actual pH",
        luminosity: "Luminosity",
        basicSolutionTank: "Basic solution tank",
        panelConfig: "Panel Config",
        panelData: "Panel Data",
        installationAddress: "Installation Address",
        installationDate: "Installation date",
        nominalCapacity: "Nominal capacity",
        elapsedTime: "Elapsed time",
        lastSync: "Last synchronization",
        productionNominalCapacity: "Production nominal capacity",
        change: "Change",
        updatingGlycerolPump: "Updating Glycerol Pump",
        updatingGlycerolPH: "Updating Glycerol pH",
        h2Production: "H2 Production",
        timeInMinutes: "Time in minutes",
        highTemperature: "High Temperature",
        lowTemperature: "Low Temperature",
        highPh: "High pH",
        lowPh: "Low pH",
        maxSyncTime: "Max Synchronization Time",
        export: "Export",
        exportReport: "Export Report",
        commandsLog: "Commands LOG",
        comparative: "Comparative",
        commandType: "Command type",
        parameter: "Parameter",
        situation: "Situation",
        date: "Date",
        productionValue: "Production Value",
        format: "Format",
        logsReport: "LOGS Report",
        h2ProductionReport: "H2 production report",
        comparisonReport: "Comparison Report",
        sensorsTable: "Sensors Table",
        sensors: "Sensors",
    }
}

export default enUsTranslations

