import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import { useTranslation } from 'react-i18next'

export function MultiLineChart({ level, actualPh, pump, temperature, luminosity, h2 }) {
    const chartRef = useRef(null)
    const { t } = useTranslation()

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d')

        const lineChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: Array.from({ length: 16 }, (_, i) => i + 1),
                datasets: [
                    {
                        label: t("level"),
                        data: level,
                        borderColor: 'rgba(249, 65, 68, 1)',
                        pointBackgroundColor: 'rgba(249, 65, 68, 1)',
                        borderWidth: 3,
                        pointRadius: 5,
                        pointHoverRadius: 8,
                        fill: false
                    },
                    {
                        label: t("actualpH"),
                        data: actualPh,
                        borderColor: 'rgba(225, 59, 163, 1)',
                        pointBackgroundColor: 'rgba(225, 59, 163, 1)',
                        borderWidth: 3,
                        pointRadius: 5,
                        pointHoverRadius: 8,
                        fill: false
                    },
                    {
                        label: t("pump"),
                        data: pump,
                        borderColor: 'rgba(248, 150, 30, 1)',
                        pointBackgroundColor: 'rgba(248, 150, 30, 1)',
                        borderWidth: 3,
                        pointRadius: 5,
                        pointHoverRadius: 8,
                        fill: false
                    },
                    {
                        label: t("temperature"),
                        data: temperature,
                        borderColor: 'rgba(70, 108, 209, 1)',
                        pointBackgroundColor: 'rgba(70, 108, 209, 1)',
                        borderWidth: 3,
                        pointRadius: 5,
                        pointHoverRadius: 8,
                        fill: false
                    },
                    {
                        label: t("luminosity"),
                        data: luminosity,
                        borderColor: 'rgba(67, 170, 139, 1)',
                        pointBackgroundColor: 'rgba(67, 170, 139, 1)',
                        borderWidth: 3,
                        pointRadius: 5,
                        pointHoverRadius: 8,
                        fill: false
                    },
                    {
                        label: "H2",
                        data: h2,
                        borderColor: 'rgba(87, 151, 15, 1)',
                        pointBackgroundColor: 'rgba(87, 151, 15, 1)',
                        borderWidth: 3,
                        pointRadius: 5,
                        pointHoverRadius: 8,
                        fill: false
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                clip: false,
                scales: {
                    y: {
                        min: 0,
                        max: 100,
                        ticks: {
                            crossAlign: "far",
                            stepSize: 20,
                        },
                        grid: {
                            drawTicks: false,
                            display: true,
                            color: 'rgba(220, 220, 220, 0.8)',
                        },
                        border: {
                            display: false,
                        }
                    },
                },
                layout: {
                    padding: {
                        top: 20,
                        bottom: 30,
                        left: 15,
                        right: 15,
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        align: 'start',
                        position: 'bottom',
                        labels: {
                            boxHeight: 8,
                            boxWidth: 8,
                            usePointStyle: true,
                            pointStyle: 'circle',
                            padding: 20,
                            font: {
                                size: 16
                            },
                        }
                    },
                },
            }
        })

        return () => {
            lineChart.destroy()
        }
    }, [level, actualPh, pump, temperature, luminosity, h2, t])

    return (
        <canvas ref={chartRef} />
    )
}
