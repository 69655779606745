import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md"
import { MultiLineChart } from "../Charts/MultiLineChart"
import React, { useState, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

export const Comparative = forwardRef((props, ref) => {
    const { t } = useTranslation()

    const [levelStatus, setLevelStatus] = useState(true)
    const [actualPhStatus, setActualPhStatus] = useState(true)
    const [pumpStatus, setPumpStatus] = useState(true)
    const [h2ProductionStatus, setH2ProductionStatus] = useState(true)
    const [luminosityStatus, setLuminosityStatus] = useState(true)
    const [temperatureStatus, setTemperatureStatus] = useState(true)

    const { chartsProductionH2, chartsTkSoluctionBasicLevel, chartsTemperature, chartsLuminosity, 
        chartsPh, chartsPump } = useSelector(
            (state) => state.panel
    )

    return (
        <div ref={ref}>
            <div className="flex flex-col bg-white mt-6 mx-4 py-4 px-6 rounded-xl h-96">
                <MultiLineChart
                    level={chartsTkSoluctionBasicLevel}
                    actualPh={chartsPh}
                    temperature={chartsTemperature}
                    h2={chartsProductionH2}
                    luminosity={chartsLuminosity}
                    pump={chartsPump}
                />

            </div>
            <div className="flex flex-col bg-white my-6 mx-4 py-6 px-6 rounded-xl h-auto space-y-8">
                <h1 className="font-poppins font-bold text-h2vtext">{t("sensorsTable")}</h1>
                <table
                    className="min-w-full h-1/2 border text-center text-sm font-light border-neutral-400">
                    <thead className="border-b font-medium border-neutral-400">
                        <tr>
                            <th
                                scope="col"
                                className="border-r px-6 py-4 border-neutral-400"
                            />
                            <th
                                scope="col"
                                className="border-r px-4 py-4 border-neutral-400">
                                {t("sensors")}
                            </th>
                            {Array.from({ length: 15 }, (_, i) => i + 1).map((item, _) =>
                                <th
                                    scope="col"
                                    className="border-r px-6 py-4 border-neutral-400">
                                    {item}
                                </th>)}
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b border-neutral-400">
                            <td
                                className="flex justify-center whitespace-nowrap border-r py-4 font-medium border-neutral-400">
                                <div onClick={() => { setLevelStatus(!levelStatus) }} className="cursor-pointer">
                                    {levelStatus ? <MdCheckBox className="w-8 h-8 text-gray-600" />
                                        : <MdCheckBoxOutlineBlank className="w-8 h-8 text-gray-600" />
                                    }
                                </div>
                            </td>
                            <td
                                className="whitespace-nowrap text-start border-r px-4 py-4 font-bold border-neutral-400 text-h2v-chart-red">
                                {t("level")}
                            </td>
                        </tr>
                        <tr className="border-b border-neutral-400">
                            <td
                                className="flex justify-center whitespace-nowrap border-r py-4 font-medium border-neutral-400">
                                <div onClick={() => { setActualPhStatus(!actualPhStatus) }} className="cursor-pointer">
                                    {actualPhStatus ? <MdCheckBox className="w-8 h-8 text-gray-600" />
                                        : <MdCheckBoxOutlineBlank className="w-8 h-8 text-gray-600" />
                                    }
                                </div>
                            </td>
                            <td
                                className="whitespace-nowrap text-start border-r px-4 py-4 font-bold border-neutral-400 text-h2v-chart-rose">
                                {t("actualpH")}
                            </td>
                        </tr>
                        <tr className="border-b border-neutral-400">
                            <td
                                className="flex justify-center whitespace-nowrap border-r py-4 font-medium border-neutral-400">
                                <div onClick={() => { setPumpStatus(!pumpStatus) }} className="cursor-pointer">
                                    {pumpStatus ? <MdCheckBox className="w-8 h-8 text-gray-600" />
                                        : <MdCheckBoxOutlineBlank className="w-8 h-8 text-gray-600" />
                                    }
                                </div>
                            </td>
                            <td
                                className="whitespace-nowrap text-start border-r px-4 py-4 font-bold border-neutral-400 text-h2v-chart-yellow">
                                {t("pump")}
                            </td>
                        </tr>
                        <tr className="border-b border-neutral-400">
                            <td
                                className="flex justify-center whitespace-nowrap border-r py-4 font-medium border-neutral-400">
                                <div onClick={() => { setH2ProductionStatus(!h2ProductionStatus) }} className="cursor-pointer">
                                    {h2ProductionStatus ? <MdCheckBox className="w-8 h-8 text-gray-600" />
                                        : <MdCheckBoxOutlineBlank className="w-8 h-8 text-gray-600" />
                                    }
                                </div>
                            </td>
                            <td
                                className="whitespace-nowrap text-start border-r px-4 py-4 font-bold border-neutral-400 text-h2v-green">
                                {t("h2Production")}
                            </td>
                        </tr>
                        <tr className="border-b border-neutral-400">
                            <td
                                className="flex justify-center whitespace-nowrap border-r py-4 font-medium border-neutral-400">
                                <div onClick={() => { setLuminosityStatus(!luminosityStatus) }} className="cursor-pointer">
                                    {luminosityStatus ? <MdCheckBox className="w-8 h-8 text-gray-600" />
                                        : <MdCheckBoxOutlineBlank className="w-8 h-8 text-gray-600" />
                                    }
                                </div>
                            </td>
                            <td
                                className="whitespace-nowrap text-start border-r px-4 py-4 font-bold border-neutral-400 text-h2v-chart-cyan">
                                {t("luminosity")}
                            </td>
                        </tr>
                        <tr className="border-b border-neutral-400">
                            <td
                                className="flex justify-center whitespace-nowrap border-r py-4 font-medium border-neutral-400">
                                <div onClick={() => { setTemperatureStatus(!temperatureStatus) }} className="cursor-pointer">
                                    {temperatureStatus ? <MdCheckBox className="w-8 h-8 text-gray-600" />
                                        : <MdCheckBoxOutlineBlank className="w-8 h-8 text-gray-600" />
                                    }
                                </div>
                            </td>
                            <td
                                className="whitespace-nowrap text-start border-r px-4 py-4 font-bold border-neutral-400 text-h2v-blue">
                                {t("temperature")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
},)