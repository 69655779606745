import agent from "./api";


const PANEL = "/panel"

export async function getPanel() {
    return await agent.get(PANEL)
}

export async function postPanel(installationAddress, installationDate, nominalCapacity, elapsedTime) {
    return await agent.post(PANEL, { installationAddress, installationDate, nominalCapacity, elapsedTime })
}

export async function postCommand(type, desiredValue) {
    return await agent.post(PANEL + "/command", { type, desiredValue })
}

export async function getCommand(page, perPage) {
    return await agent.get(`${PANEL}/command`, {
        params: {
            page: page,
            perPage: perPage,
        },
    });
}

export async function getCharts(startDate, endDate) {
    return await agent.get(PANEL + "/charts", { startDate, endDate })
}

export async function getDashboard() {
    return await agent.get(PANEL + "/dashboard")
}

export async function postConfig(phHigh, phLow, temperatureHigh, temperatureLow,
    reactorTemperatureHigh, reactorTemperatureLow, maxSyncTime) {
    return await agent.post(PANEL + "/config", {
        phHigh, phLow, temperatureHigh, temperatureLow,
        reactorTemperatureHigh, reactorTemperatureLow, maxSyncTime
    })
}

export async function getConfig() {
    return await agent.get(PANEL + "/config")
}