import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

export function LineChart({ data }) {
    const chartRef = useRef(null);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        const lineChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: data,
                datasets: [
                    {
                        data: data,
                        fill: true,
                        borderColor: 'rgba(87, 151, 15, 1)',
                        backgroundColor: 'rgba(87, 151, 15, 0.2)',
                        pointHoverRadius: 4,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                clip: false,
                scales: {
                    y: {
                        min: 20,
                        max: 100,
                        ticks: {
                            crossAlign: 'far',
                            stepSize: 20,
                            callback: (value) => value + '%',
                        },
                        grid: {
                            drawTicks: false,
                            display: true,
                        },
                        border: {
                            display: false,
                        },
                    },
                    x: {
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                    },
                },
                interaction: {
                    mode: 'nearest',
                    intersect: false,
                },
                hover: {
                    mode: 'index',
                    intersect: false,
                },
            },
        });

        return () => {
            lineChart.destroy();
        };
    }, [data]);

    return (
        <div className="w-full h-56 overflow-hidden">
            <canvas ref={chartRef} />
        </div>
    );
}
