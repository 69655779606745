import { I18n } from "../components/I18n/I18n.jsx"
import { Sidebar } from "../components/Sidebar/Sidebar.jsx"
import { Select } from "../components/Select/Select.jsx"
import { Button } from "../components/Button/Button.jsx"
import { useTranslation } from "react-i18next"
import React, { useState, useRef, useEffect } from "react"
import { PiFilePdf } from "react-icons/pi"
import { CommandLogs } from "../components/Report/CommandLogs.jsx"
import { Comparative } from "../components/Report/Comparative.jsx"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import logo from "../assets/main_logo.png"
import bottom from "../assets/pdf_bottom.png"
import { useDispatch } from "react-redux"
import { retrieveCharts } from "../features/panel/panelActions.js"
import { H2Production } from "../components/Report/H2Production.jsx"

export function ReportPage() {
    const { t } = useTranslation()
    const chartRef = useRef()
    const logsRef = useRef()
    const dispatch = useDispatch()

    const actualPageValues = [
        "logs",
        "h2Production",
        "comparative"
    ]

    const formats = [
        { value: "pdf", label: "PDF", icon: <PiFilePdf></PiFilePdf> }
    ]

    const d = new Date()
    const day = d.getUTCDate()
    const month = d.getUTCMonth()
    const year = d.getUTCFullYear()

    const months = [
        t('january'),
        t('february'),
        t('march'),
        t('april'),
        t('may'),
        t('june'),
        t('july'),
        t('august'),
        t('september'),
        t('october'),
        t('november'),
        t('december')
    ]

    const [fromDay, setFromDay] = useState(day.toString())
    const [toDay, setToDay] = useState(day.toString())
    const [fromMonth, setFromMonth] = useState(months[month])
    const [toMonth, setToMonth] = useState(months[month])
    const [fromYear, setFromYear] = useState(year.toString())
    const [toYear, setToYear] = useState(year.toString())

    useEffect(() => {
        dispatch(retrieveCharts({ startDate: `${fromYear}-${fromMonth}-${fromDay}`, endDate: `${toYear}-${toMonth}-${toDay}` }))
    }, [fromDay, toDay, fromMonth, toMonth, fromYear, toYear, dispatch]);


    const [actualPage, setActualPage] = useState(actualPageValues[0])

    const exportPDF = () => {
        html2canvas(actualPage === actualPageValues[0] ? logsRef.current : chartRef.current).then((canvas) => {
            const imgData = canvas.toDataURL("image/png")
            const pdf = new jsPDF()

            pdf.addImage(logo, 'PNG', 10, 7, 43, 16);

            pdf.setFontSize(18)

            const text = (actualPage === actualPageValues[0])
                ? t("logsReport")
                : (actualPage === actualPageValues[1])
                    ?  t("h2ProductionReport")
                    : t("comparisonReport")

            const textWidth = pdf.getTextWidth(text)
            const x = (pdf.internal.pageSize.width - textWidth) / 2

            pdf.text(text, x, 40)

            const imgWidth = pdf.internal.pageSize.width - 20
            const imgHeight = (canvas.height * imgWidth) / canvas.width

            pdf.addImage(imgData, 'PNG', 10, 50, imgWidth, imgHeight)

            const bottomY = pdf.internal.pageSize.height - 13;
            pdf.addImage(bottom, 'PNG', 0, bottomY, pdf.internal.pageSize.width, 13);

            const timeStamp = new Date().getTime();

            if (actualPage === actualPageValues[0]) {
                pdf.save(`logs-${timeStamp}.pdf`)
            } else if (actualPage === actualPageValues[1]) {
                pdf.save(`production-${timeStamp}.pdf`)
            } else {
                pdf.save(`comparison-${timeStamp}.pdf`)
            }
        })
    }

    return (
        <div className="flex bg-h2v-dashboard-background h-screen overflow-auto">
            <Sidebar />
            <div className="flex flex-col w-full h-full overflow-auto">
                <div className="flex items-center px-6 lg:px-12 justify-between py-6">
                    <div className="flex flex-row items-center">
                        <h1 className="text-black font-poppins font-medium text-base lg:text-h2vtext">{`${t("report")}s`}</h1>
                    </div>
                    <div className="flex flex-row space-x-6">
                        <Button
                            children={t("commandsLog")}
                            className={`self-center border-2 border-h2v-green font-poppins text-base font-medium py-2 px-4 w-48 rounded-lg 
                                ${actualPage === actualPageValues[0] ? "bg-h2v-green text-white" : "bg-white text-h2v-green"}`}
                            onClick={() => { setActualPage(actualPageValues[0]) }}
                        />
                        <Button
                            children={t("h2Production")}
                            className={`self-center border-2 border-h2v-green font-poppins text-base font-medium py-2 px-4 w-48 rounded-lg 
                                ${actualPage === actualPageValues[1] ? "bg-h2v-green text-white" : "bg-white text-h2v-green"}`}
                            onClick={() => { setActualPage(actualPageValues[1]) }}
                        />
                        <Button
                            children={t("comparative")}
                            className={`self-center border-2 border-h2v-green font-poppins text-base font-medium py-2 px-4 w-48 rounded-lg 
                                ${actualPage === actualPageValues[2] ? "bg-h2v-green text-white" : "bg-white text-h2v-green"}`}
                            onClick={() => { setActualPage(actualPageValues[2]) }}
                        />
                    </div>
                    <div className="flex items-center">
                        <I18n />
                    </div>
                </div>
                <div className="px-6 lg:px-6">
                    <div className="border-t border-gray-300 w-full" />
                </div>
                <div className="flex flex-row bg-white mt-4 mx-4 py-4 px-6 rounded-xl items-center justify-between">
                    <div className="flex flex-row items-center space-x-4">
                        <p className="whitespace-nowrap">{t("exportReport")}</p>
                        <Select
                            value={fromDay}
                            options={Array.from({ length: day }, (_, index) => {
                                const number = (index + 1).toString().padStart(2, '0')
                                return {
                                    value: number,
                                    label: number
                                }
                            })}
                            onChange={(e) => setFromDay(e.target.value)}
                            className="appearance-none cursor-pointer w-16 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 px-4 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <Select
                            value={fromMonth}
                            options={months.slice(0, month + 1).map((month, _) => ({
                                value: month,
                                label: month,
                            }))}
                            onChange={(e) => setFromMonth(e.target.value)}
                            className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-8 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <Select
                            value={fromYear}
                            options={Array.from({ length: year - 1900 + 1 }, (_, index) => {
                                const number = (1900 + index).toString()
                                return {
                                    value: number,
                                    label: number
                                }
                            })}
                            onChange={(e) => setFromYear(e.target.value)}
                            className="appearance-none cursor-pointer w-24 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <p>{t("until")}</p>
                        <Select
                            value={toDay}
                            options={Array.from({ length: 31 }, (_, index) => {
                                const number = (index + 1).toString().padStart(2, '0')
                                return {
                                    value: number,
                                    label: number
                                }
                            })}
                            onChange={(e) => setToDay(e.target.value < fromDay ? toDay : e.target.value)}
                            className="appearance-none cursor-pointer w-16 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 px-4 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <Select
                            value={toMonth}
                            options={months.slice(0, months.length).map((month, index) => ({
                                value: month,
                                label: month,
                            }))}
                            onChange={(e) => setToMonth(months.indexOf(e.target.value) >= months.indexOf(fromMonth) ? e.target.value : toMonth)}
                            className="appearance-none cursor-pointer h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-8 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <Select
                            value={toYear}
                            options={Array.from({ length: year - 1900 + 1 }, (_, index) => {
                                const number = (1900 + index).toString()
                                return {
                                    value: number,
                                    label: number
                                }
                            })}
                            onChange={(e) => setToYear(e.target.value < fromYear ? toYear : e.target.value)}
                            className="appearance-none cursor-pointer w-24 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                        <p>{t("format")}</p>
                        <Select
                            options={formats}
                            className="appearance-none cursor-pointer w-24 h-9 bg-white border font-poppins font-bold border-h2v-blue text-h2v-blue py-2 pl-4 pr-6 rounded leading-tight focus:outline-none"
                            iconColor="text-h2v-blue"
                        />
                    </div>
                    <Button
                        children={t("export")}
                        className="self-center bg-h2v-green font-poppins text-base text-white font-medium py-2 px-4 w-22 ml-4 rounded-lg"
                        onClick={exportPDF}
                    />
                </div>

                {actualPage === actualPageValues[0] ?
                    <CommandLogs ref={logsRef} /> : actualPage === actualPageValues[1] ?
                        <H2Production ref={chartRef} /> : <Comparative ref={chartRef} />}
            </div>
        </div>
    )
}
