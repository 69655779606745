import { useTranslation } from "react-i18next";
import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { retrieveCommand } from "../../features/panel/panelActions";

export const CommandLogs = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { commandList, total } = useSelector((state) => state.panel);

    useEffect(() => {
        dispatch(retrieveCommand({ perPage: total || 1000 }));
    }, [dispatch, total]);

    return (
        <div className="flex flex-col bg-white my-6 mx-4 rounded-xl h-full overflow-hidden" ref={ref}>
            <div className="relative flex-1 hover:overflow-y-scroll">
                <table className="min-w-full table-fixed">
                    <colgroup>
                        <col style={{ width: "50%" }} />
                        <col style={{ width: "25%" }} />
                        <col style={{ width: "25%" }} />
                    </colgroup>
                    <thead className="bg-blue-200 text-black sticky top-0 z-20">
                        <tr>
                            <th className="text-left py-4 px-6 font-bold">{t("commandType")}</th>
                            <th className="text-center py-4 px-6 font-bold">{t("parameter")}</th>
                            <th className="text-right py-4 px-8 font-bold">{t("situation")}</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y-2">
                        {commandList.map((item, index) => (
                            <tr key={index}>
                                <td className="text-left py-4 px-6">
                                    {item.type === "UPDATING_GLICEROL_PH" ? t("updatingGlycerolPH") : t("updatingGlycerolPump")}
                                </td>
                                <td className="text-center py-6 px-6">{item.desiredValue}</td>
                                <td className="flex flex-row items-center justify-end py-4 px-6">
                                    <div
                                        className={`w-2 h-2 rounded-full ${item.status === "WAITING" ? "bg-yellow-500" : "bg-green-500"} mr-2`}
                                    ></div>
                                    {item.status === "WAITING" ? t("waiting") : t("completed")}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
});
