import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { LineChart } from "../Charts/LineChart"

export const H2Production = forwardRef((props, ref) => {
    const { t } = useTranslation()

    const { chartsProductionH2 } = useSelector(
        (state) => state.panel
    )

    const d = new Date()
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    const date = `${day}/${month}/${year}`

    return (
        <div className="flex flex-col bg-white mx-4 my-6 px-4 py-4 max-h-full rounded-lg" ref={ref}>
            <span className="font-bold">{t("productionVolume")}</span>
            <div className="py-8 h-full">
                <LineChart data={chartsProductionH2} />
            </div>
            <table className="min-w-full table-fixed font-bold">
                <thead className="bg-gray-200 text-black">
                    <tr>
                        <th className="text-left py-4 px-6">{t("date")}</th>
                        <th className="text-center py-4 px-6">{t("productionValue")}</th>
                    </tr>
                </thead>
                <tbody className="divide-y-2">
                    {chartsProductionH2.map((item, index) => (
                        <tr key={index}>
                            <td className="text-left py-4 px-6">
                                {date}
                            </td>
                            <td className=" text-center py-4 px-6">
                                {item}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
},)