import { createAsyncThunk } from "@reduxjs/toolkit"
import { getCommand, getDashboard, getPanel, postCommand, postPanel, getCharts, postConfig, getConfig } from "../../api/panel"

export const retrievePanel = createAsyncThunk(
    'panel/get',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getPanel()

            return response.data[0]
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)

export const createPanel = createAsyncThunk(
    'panel/post',
    async ({ installationAddress, installationDate, nominalCapacity, elapsedTime }, { rejectWithValue }) => {
        try {
            const response = await postPanel(installationAddress, installationDate, nominalCapacity, elapsedTime)
            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)

export const retrieveCommand = createAsyncThunk(
    'panel/command',
    async ({ page, perPage } , { rejectWithValue }) => {
        try {
            const response = await getCommand(page, perPage)

            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)

export const sendCommand = createAsyncThunk(
    'panel/command/post',
    async ({ type, desiredValue}, { rejectWithValue }) => {
        try {
            const response = await postCommand(type, desiredValue)

            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)


export const retrieveDashboardData = createAsyncThunk(
    'panel/dashboard',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getDashboard()

            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)

export const retrieveCharts = createAsyncThunk(
    'panel/charts',
    async ({ startDate, endDate }, { rejectWithValue }) => {
        try {
            const response = await getCharts(startDate + "-00:00:00", endDate + "-23:59:59")
            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)


export const retrieveConfig = createAsyncThunk(
    'panel/config',
    async (_, { rejectWithValue }) => {
        try {
            const response = await getConfig()
            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)

export const sendConfig = createAsyncThunk(
    'panel/config/post',
    async ({ phHigh, phLow, temperatureHigh, temperatureLow, reactorTemperatureHigh, reactorTemperatureLow, maxSyncTime }, { rejectWithValue }) => {
        try {
            const response = await postConfig(phHigh, phLow, temperatureHigh, temperatureLow, 
                reactorTemperatureHigh, reactorTemperatureLow, maxSyncTime)

            return response.data
        } catch (error) {
            if (error.response) {
                const { status, data } = error.response
                return rejectWithValue({ status, data })
            } else {
                return rejectWithValue(error.message || 'Network error')
            }
        }
    }
)